const baseApi = {
    sofreight: process.env.VUE_APP_SOFREIGHT,
    visibility: process.env.VUE_APP_VISIBILITY,
    mapRoute: process.env.VUE_APP_MAP_ROUTE,
    mapStatic: process.env.VUE_APP_MAP_STATIC,
    n2sws: process.env.VUE_APP_VISIBILITY_WS,
    file: process.env.VUE_APP_VISIBILITY_FILE,
    toCntrans: process.env.VUE_APP_CNTRANS,
}

export default baseApi;