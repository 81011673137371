<template>
    <div class="page-breadcrumb">
        <span v-if="backTitle" class="backTitle" @click="back">{{backTitle}}</span>
        <h5 v-if="arrow">{{arrow}}</h5>
        <a>
            {{ title }}
            <el-tooltip class="breadcrumb-item" effect="dark" placement="bottom-start">
                <div slot="content">
                    <span style="margin-right: 8px;" v-html="content"></span>
                    <a class="breadcrumb-item__content" v-if="link" href="javascript:;" @click="linkTo(link)">{{ linkText }}</a>
                </div>
                <span class="el-icon-question"></span>
            </el-tooltip>
        </a>
      <span v-if="breadcrumb_Type == 'freight'"></span>
        <i></i>
    </div>
</template>

<script>
    export default {
        data() {
            return {
              breadcrumb_Type:''
            }
        },
        props: {
            title: String,
            link: String,
            content: String,
            linkText: String,
            backTitle:String,
            arrow:String,
            BreadcrumbType: {
              type: String,
              default: ''
            },
        },
      watch:{
        BreadcrumbType(newVal) {
          console.log(newVal,'this.BreadcrumbType');
          if(newVal){
            this.breadcrumb_Type = newVal;
          }

        }
      },
      created() {
        this.breadcrumb_Type = this.BreadcrumbType;
      },
      methods: {
            linkTo(link) {
                console.log(link)
                this.$router.push({
                    path: link
                })
            },
            back() {
                this.$emit("back")
            }
        }
    }
</script>

<style lang="scss" scoped>
.page-breadcrumb {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    margin-bottom: 14px;
    /*padding-left: 20px;*/
    h5 {
        display: inline;
    }
    .backTitle {
        color: #409EFF;
    }
    .backTitle:hover {
        cursor: pointer;
        color: #2c78eb;
    }
    a {
        font-size: 1.75rem;
        color: #3f4144 !important;
        position: relative;
        display: inline-block;
    }
    i {
        flex: 1;
        position: relative;
        display: inline-block;
        margin-left: 14px;
        box-sizing: border-box;
        &:after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 1px;
            background-color: #e6e6e6;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
        }
    }
}
/deep/ .breadcrumb-item__content {
    text-decoration: none;
    color: #fff;
    &:hover {
        color: #2c78eb;
    }
}
</style>
