export const SET_TOKEN = "SET_TOKEN"
export const SET_NAME = "SET_NAME"
export const SET_AVATAR = "SET_AVATAR"
export const SET_ROLES = "SET_ROLES"
export const SET_USERINFO = "SET_USERINFO"
export const SET_LOGININFO = "SET_LOGININFO"
export const SET_SENDEMAILRES = "SET_SENDEMAILRES"
export const SET_NEWPASS = "SET_NEWPASS"
export const GET_CHECKCODEINFO = "GET_CHECKCODEINFO"
export const SET_MID = "SET_MID"
export const ORDER_ID = "ORDER_ID"
export const STATISTICS = "STATISTICS"
export const STATISTICSV = "STATISTICSV"
export const USERRTX = "USERRTX"