import {
    indexQuoteList
} from "@/api/dashboard"

import {
    GET_QUOTEINDEXLIST
} from "./mutation-types"

const dashboard = {
    state: {
        quoteList: []
    },

    mutations: {
        [GET_QUOTEINDEXLIST]: (state, list) => {
            state.quoteList = list;
        }
    },

    actions: {
        getIndexQuoteList({ commit }, data) {
            return indexQuoteList(data).then(res => {
                const list = res;
                commit("GET_QUOTEINDEXLIST", list.data);
            });
        }
    }
}

export default dashboard