import {
    portListFromSetting,
    portListFromFreight,
    topSearch,
    chatList,
    chatDetail,
    cooperationSave,
    StatisticsVisit,
    logoUpdate,
    logoListApi,
    cooperationDel,
    userRtxApi,
    fileDelApi
} from "@/api/commons"

import {
    GET_PORTLISTFROMGSETTING,
    GET_PORTLISTFROMFREIGHT,
    SET_OFFSETHEIGHT,
    GET_SEARCHLIST,
    GET_CHATLIST,
    GET_CHATDETAIL,
    POST_REPLY,
    VISIT_PAGE,
    LOGO_UPUATE,
    LOGO_LIST,
    DEL_INFO,
    USERRTX,
    FILEDEL
} from "./mutation-types"

const commons = {
    state: {
        portListFromSetting: {},
        portListFromFreihgt: {},
        offsetHeight: 380,
        searchList: {},
        chatList: {},
        replyInfo: {},
        chatDetail: {},
        visitPage: {},
        logodata: {},
        logoinfo: {},
        del_info: {},
        userRtxList: {},
        fileDel: {}
    },

    mutations: {
        [GET_PORTLISTFROMGSETTING]: (state, list) => {
            state.portListFromSetting = list;
        },
        [GET_PORTLISTFROMFREIGHT]: (state, list) => {
            state.portListFromFreihgt = list;
        },
        [SET_OFFSETHEIGHT]: (state, height) => {
            state.offsetHeight = height;
        },
        [GET_SEARCHLIST]: (state, list) => {
            state.searchList = list;
        },
        [GET_CHATLIST]: (state, list) => {
            state.chatList = list
        },
        [GET_CHATDETAIL]: (state, list) => {
            state.chatDetail = list;
        },
        [POST_REPLY]: (state, info) => {
            state.replyInfo = info;
        },
        [VISIT_PAGE]: (state, info) => {
            state.visitPage = info;
        },
        [LOGO_UPUATE]: (state, info) => {
            state.logodata = info;
        },
        [LOGO_LIST]: (state, info) => {
            state.logoinfo = info;
        },
        [DEL_INFO]: (state, info) => {
            state.del_info = info;
        },
        [USERRTX]: (state, info) => {
            state.userRtxList = info
        },
        [FILEDEL]: (state, info) => {
            state.fileDel = info
        }
    },

    actions: {
        getPortListFromSetting({
            commit
        }, data) {
            return portListFromSetting({
                searchVal: data
            }).then(res => {
                const list = res;
                commit("GET_PORTLISTFROMGSETTING", list.data);
            });
        },
        getPortListFromFreight({
            commit
        }, data) {
            return portListFromFreight(data).then(res => {
                const list = res;
                commit("GET_PORTLISTFROMFREIGHT", list.data);
            })
        },
        setOffsetHeight({
            commit
        }) {
            let height = document.documentElement.offsetHeight;
            console.log(height, "height");
            height = height - 210;
            commit("SET_OFFSETHEIGHT", height);
        },
        getSearchList({
            commit
        }, data) {
            return topSearch(data).then(res => {
                const list = res;
                commit("GET_SEARCHLIST", list);
            });
        },
        getChatDetail({
            commit
        }, data) {
            return chatDetail(data).then(res => {
                const list = res;
                commit("GET_CHATDETAIL", list)
            })
        },
        postReply({
            commit
        }, data) {
            return cooperationSave(data).then(res => {
                const info = res;
                commit("POST_REPLY", info);
            })
        },
        ReplyDel({
            commit
        }, data) {
            return cooperationDel(data).then(res => {
                const info = res;
                commit("DEL_INFO", info);
            })
        },
        StatisticsVisit({
            commit
        }, data) {
            return StatisticsVisit(data).then(res => {
                const info = res;
                commit("VISIT_PAGE", info);
            })
        },
        updateLoge({
            commit
        }, data) {
            return logoUpdate(data).then(res => {
                const info = res;
                commit("LOGO_UPUATE", info);
            })
        },
        LogeList({
            commit
        }, data) {
            return logoListApi(data).then(res => {
                const info = res;
                commit("LOGO_LIST", info);
            })
        },
        userRtxListfn({
            commit
        }, data) {
            return userRtxApi(data).then(res => {
                const rtxList = res;
                commit("USERRTX", rtxList);
            });
        },
        fileDelFn({
            commit
        }, data) {
            return fileDelApi(data).then(res => {
                const delRes = res;
                commit("FILEDEL", delRes);
            });
        },
    }
}

export default commons