import {
    quoteRequestsList,
    quoteRequestsDetail,
    quoteRequestsForm,
    packageType,
    chargesCode,
    sendQuote,
    confirmQuote,
    historyCharges
} from "@/api/quotes"

import {
    GET_QUOTEREQUESTSLIST,
    GET_QUOTEREQUESTSDETAIL,
    GET_PACKAGETYPE,
    GET_CHARGESCODE,
    GET_SENDQUOTEINFO,
    GET_CONFIRMQUOTEINFO,
    GET_QUOTEREQUESTSFORM,
    GET_HISTORYCHARGES,
    REPLICATE_QUOTE
} from "./mutation-types"

const quotes = {
    state: {
        quoteRequestsList: {},
        quoteRequestsDetail: {},
        packageType: [],
        chargesCodeList: [],
        sendQuoteInfo: {},
        confirmQuoteInfo: {},
        quoteRequestsForm: {},
        historyChargesList: {},
        replicateInfo: {}
    },

    mutations: {
        [GET_QUOTEREQUESTSLIST]: (state, list) => {
            state.quoteRequestsList = list
        },
        [GET_QUOTEREQUESTSDETAIL]: (state, info) => {
            state.quoteRequestsDetail = info
        },
        [GET_PACKAGETYPE]: (state, list) => {
            state.packageType = list
        },
        [GET_CHARGESCODE]: (state, list) => {
            state.chargesCodeList = list
        },
        [GET_SENDQUOTEINFO]: (state, list) => {
            state.sendQuoteInfo = list
        },
        [GET_CONFIRMQUOTEINFO]: (state, info) => {
            state.confirmQuoteInfo = info;
        },
        [GET_QUOTEREQUESTSFORM]: (state, info) => {
            state.quoteRequestsForm = info;
        },
        [GET_HISTORYCHARGES]: (state, info) => {
            state.historyChargesList = info;
        },
        [REPLICATE_QUOTE]: (state, info) => {
            state.replicateInfo = info;
        }
    },

    actions: {
        setReplicateInfo({ commit }, info) {
            commit("REPLICATE_QUOTE", info);
        },
        getQuoteRequestsList({ commit }, data) {
            return quoteRequestsList(data).then(res => {
                const list = res;
                commit("GET_QUOTEREQUESTSLIST", list);
            });
        },
        getQuoteRequestsDetail({ commit }, data) {
            return quoteRequestsDetail(data).then(res => {
                if(res.data.detail && res.data.detail.quotes.length > 0) {
                    for (let index in res.data.detail.quotes) {
                        res.data.detail.quotes[index].case_id = parseInt(index) + 1;
                    }
                }
                commit("GET_QUOTEREQUESTSDETAIL", res);
            });
        },
        postQuoteRequestsForm({ commit }, data) {
            return quoteRequestsForm(data).then(res => {
                const info = res;
                commit("GET_QUOTEREQUESTSFORM", info);
            })
        },
        getPackageType({ commit }) {
            return packageType().then(res => {
                const list = res.data;
                commit("GET_PACKAGETYPE", list);
            })
        },
        getChargesCode({ commit }) {
            return chargesCode().then(res => {
                const list = res.data;
                commit("GET_CHARGESCODE", list);
            })
        },
        sendQuoteList({ commit }, data) {
            return sendQuote(data).then(res => {
                const list = res;
                commit("GET_SENDQUOTEINFO", list);
            })
        },
        getConfirmQuoteInfo({ commit }, data) {
            return confirmQuote(data).then(res => {
                const info = res;
                commit("GET_CONFIRMQUOTEINFO", info);
            })
        },
        getHistoryCharges({ commit }, data) {
            return historyCharges(data).then(res => {
                const info = res;
                commit("GET_HISTORYCHARGES", info);
            })
        }
    }
}

export default quotes