export const GET_COOPERATIONRESPONSE = "GET_COOPERATIONRESPONSE";
export const GET_SAVECOOPERATIONRESPONSE = "GET_SAVECOOPERATIONRESPONSE";
export const GET_COOPERATIONLIST = "GET_COOPERATIONLIST";
export const GET_COOPERATIONSTARMARKLIST = "GET_COOPERATIONSTARMARKLIST";
export const GET_COOPERATIONLISTNEW = "GET_COOPERATIONLISTNEW";
export const GET_COOPERATIONLISTHAVEMORE = "GET_COOPERATIONLISTHAVEMORE";
export const GET_COOPERATIONLISTCACHE = "GET_COOPERATIONLISTCACHE";
export const GET_COOPERATIONSUBJECTLIST = "GET_COOPERATIONSUBJECTLIST";
export const GET_ACCOUNTTLIST = "GET_ACCOUNTTLIST";
export const GET_UNREADCOUNT = "GET_UNREADCOUNT";
export const GET_COOPERATIONSUBJECT = "GET_COOPERATIONSUBJECT";
export const GET_COOPERATIONGROUPLIST = "GET_COOPERATIONGROUPLIST";
export const GET_COOPERATIONGROUPLISTNEW = "GET_COOPERATIONGROUPLISTNEW";
export const SET_CURRENTCHATINGSUBJECTID = "SET_CURRENTCHATINGSUBJECTID";
export const SET_WECHAT_QR_STATUS_MSG = "SET_WECHAT_QR_STATUS_MSG"
export const SET_WECHAT_QR_DIALOG_SHOW = "SET_WECHAT_QR_DIALOG_SHOW"
export const SET_WECHAT_INFO_SCANNED = "SET_WECHAT_INFO_SCANNED"
export const SET_WECHAT_INFO_AUTHED = "SET_WECHAT_INFO_AUTHED"
export const SET_WS_HANDLER = "SET_WS_HANDLER"
export const SET_WS_FALLBACK_COUNT = "SET_WS_FALLBACK_COUNT"
export const GET_WS_HANDLER = "GET_WS_HANDLER"
export const GET_WS_FALLBACK_COUNT = "GET_WS_FALLBACK_COUNT"
export const SET_COOPERATIONTOTAL = "SET_COOPERATIONTOTAL"
export const SET_WS_CLOSETIME = "SET_WS_CLOSETIME"
export const SET_IS_REGISTED = "SET_IS_REGISTED"
export const SET_WS_FALLBACK_TIME = "SET_WS_FALLBACK_TIME"
export const SET_MSG_READ_STATUE = "SET_MSG_READ_STATUE"
export const SET_MSG_STAR_MARK = "SET_MSG_STAR_MARK"
export const SET_ALL_READ = "SET_ALL_READ"

