import axios from "@/utils/http"
import baseApi from "./base"
import qs from "qs"
import fileDownload from "js-file-download";

// freight - 海运报价列表
export function freightList(data) {
    return axios.post(`${baseApi.visibility}/Freight/getFclFreight`, qs.stringify(data))
}

// freight - 拖车报价列表
export function freightList2(data) {
    return axios.post(`${baseApi.visibility}/Freight/getDrayageList`, qs.stringify(data))
}

export function pol_hot(query) {
    return axios.get(`${baseApi.sofreight}/ports/pol_hot`, {
        params: query
    })
}

export function pod_hot(query) {
    return axios.get(`${baseApi.sofreight}/ports/pod_hot`, {
        params: query
    })
}

export function localCharge(data) {
    return axios.post(`${baseApi.visibility}/Freight/getLocationFreightCharge`, qs.stringify(data))
}

// export function sendShare(data) {
//     return axios.post(`${baseApi.visibility}/FileExport/sendShare`, qs.stringify(data))
// }

export function sendShare(data) {
    return axios.post(`${baseApi.visibility}/Freight/sendShare`, qs.stringify(data))
}

export function saveQuote(data) {
    return axios.post(`${baseApi.visibility}/Freight/saveQuote`, qs.stringify(data))
}

export function getHistory(data) {
    return axios.post(`${baseApi.visibility}/Freight/getHistory`, qs.stringify(data))
}

export function upHistory(data) {
    return axios.post(`${baseApi.visibility}/Freight/updateQuote`, qs.stringify(data))
}

export function FreightExecl(data) {
    return axios.post(`${baseApi.visibility}/Freight/FreightExecl`, qs.stringify(data))
    // 下面的是从后端直接下载的方法
    return axios.post(`${baseApi.visibility}/Freight/FreightExecl`, qs.stringify(data), {
        responseType: 'blob',
    }).then(res => {
        let day1 = new Date();
        fileDownload(res, 'Freight' + day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate() + '.xlsx');
    })
}

export function getDrayageListApi(data) {
    return axios.post(`${baseApi.visibility}/Freight/getDrayageList`, qs.stringify(data))
}

export function getDrayagePortApi(data) {
    return axios.post(`${baseApi.visibility}/Freight/getDrayagePort`, qs.stringify(data))
}


export function getDrayageCityApi(query) {
    return axios.get(`${baseApi.visibility}/Freight/getDrayageCity`, {
        params: query
    })
}

export function getlevelApi(query) {
    return axios.get(`${baseApi.visibility}/Freight/getlevel`, {
        params: query
    })
}

// 清关费用列表
export function chargeConfigList(query) {
    return axios.get(`${baseApi.visibility}/ChargeConfig/index`, {
        params: query
    })
}

// 清关费用保存
export function chargeConfigSave(data) {
    return axios.post(`${baseApi.visibility}/ChargeConfig/save`, qs.stringify(data))
}

// 获取拖车详情  
export function customerList(data) {
    return axios.post(`${baseApi.visibility}/Freight/getDrayagedetail`, qs.stringify(data))
}