import {
    reportsList
} from "@/api/reports"

import {
    GET_REPORTSLIST
} from "./mutation-types"

const reports = {
    state: {
        reportsList: {}
    },

    mutations: {
        [GET_REPORTSLIST]: (state, list) => {
            state.reportsList = list
        }
    },

    actions: {
        getReportsList({ commit }, data) {
            return reportsList(data).then(res => {
                const list = res;
                commit("GET_REPORTSLIST", list.data);
            });
        }
    }
}

export default reports
