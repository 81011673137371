import axios from "@/utils/http"
import qs from 'qs';
import baseApi from "./base"
import fileDownload from "js-file-download"

export function reportsList(data) {
    return axios.post(`${baseApi.visibility}/Reports/index`, qs.stringify(data))
}

export function reportsModelList(data) {
    return axios.post(`${baseApi.visibility}/Reports/getAllReports`, qs.stringify(data))
}

export function reportsModelDetail(data) {
    return axios.post(`${baseApi.visibility}/Reports/getReportById`, qs.stringify(data))
}

export function reportsModelFields(data) {
    return axios.post(`${baseApi.visibility}/Reports/getAllFields`, qs.stringify(data))
}

export function reportsModelAdd(data) {
    return axios.post(`${baseApi.visibility}/Reports/createReport`, qs.stringify(data))
}

export function reportsModelUpdate(data) {
    return axios.post(`${baseApi.visibility}/Reports/updateReport`, qs.stringify(data))
}

export function reportsModelDelete(data) {
    return axios.post(`${baseApi.visibility}/Reports/deleteReport`, qs.stringify(data))
}

export function reportsAnalysis(data) {
    return axios.post(`${baseApi.visibility}/Analytics/index`, qs.stringify(data))
}
export function reportsAnalysisPo(data) {
    return axios.post(`${baseApi.visibility}/PoNew/getEtdReport`, qs.stringify(data))
}
export function reportsDownload(data) {
    return axios.get(`${baseApi.visibility}/Reports/reportdownload`, {
        params: data,
        responseType: 'blob',
    }).then(res => {
        fileDownload(res, 'Reports.xlsx');
    })
}

export function shipingMentApi(data) {
    return axios.post(`${baseApi.visibility}/Reports/shipingMent`, qs.stringify(data))
}

export function shipingMentExecl(data) {
    return axios.get(`${baseApi.visibility}/Reports/shipingMentExecl`, {
        params: data,
        responseType: 'blob',
    }).then(res => {
        let date = new Date();
        fileDownload(res, 'Chinatrans_shipping_list_Rates co-(' + data.username + ').xlsx');
    })
}
export function shipingExeclModel(data) {
    return axios.post(`${baseApi.visibility}/Reports/shipingExeclModel`, qs.stringify(data))
        // return axios.get(`${baseApi.visibility}/Reports/shipingExeclModel`, {
        //     params: data,
        //     responseType: 'blob',
        // }).then(res => {
        //     let date = new Date();
        //     fileDownload(res, 'Chinatrans_shipping_list_Rates co-(' + data.username + ').xlsx');
        // })
}

//总数
export function summaryApi(data) {
  return axios.get(`${baseApi.visibility}/StatisticsCustomers/summary`, {
    params:data
  })
}
//业务员列表
export function salesListApi(data) {
  return axios.get(`${baseApi.visibility}/StatisticsCustomers/salesList`, {
    params:data
  })
}
//上传客户数量接口
export function getCustomersApi(data) {
  return axios.get(`${baseApi.visibility}/StatisticsCustomers/customers`, {
    params:data
  })
}

//报价数量接口
export function getQuotationsApi(data) {
  return axios.get(`${baseApi.visibility}/StatisticsCustomers/quotations`, {
    params:data
  })
}