const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  info: state => state.user.info,
  roles: state => state.user.roles,
  freightList: state => state.freight.list
}
export default getters
