import {
    GET_FREIGHTLIST,
    GET_POLPORTLIST,
    GET_PODPORTLIST,
    GET_PODDRAYPORTLIST,
    GET_PODDRAYCITYLIST
} from "./mutation-types"
import {
    freightList,
    freightList2,
    pol_hot,
    pod_hot,
    getDrayagePortApi,
    getDrayageCityApi
} from "@/api/freight"

const freight = {
    state: {
        list: {},
        nowType: {},
        polPortList: {},
        podPortList: {},
        DrayagePortList: {},
        DrayageCityList: {},
    },
    mutations: {
        [GET_FREIGHTLIST]: (state, list) => {
            state.list = list;
        },
        [GET_POLPORTLIST]: (state, list) => {
            state.polPortList = list
        },
        [GET_PODPORTLIST]: (state, list) => {
            state.podPortList = list
        },
        [GET_PODDRAYPORTLIST]: (state, list) => {
            state.DrayagePortList = list
        },
        [GET_PODDRAYCITYLIST]: (state, list) => {
            state.DrayageCityList = list
        }
    },
    actions: {
        // freight - 海运报价列表
        getFreightList({ commit }, data) {
            return freightList(data).then(res => {
                const list = res;
                commit("GET_FREIGHTLIST", list);
            });
        },
        // freight - 拖车报价列表
        getFreightList2({ commit }, data) {
            return freightList2(data).then(res => {
                const list = res;
                commit("GET_FREIGHTLIST", list);
            });
        },
        async getPol({ commit }, query) {
            const res = await pol_hot(query);
            commit("GET_POLPORTLIST", res);
            return res.data.result;
        },
        async getPod({ commit }, query) {
            const res = await pod_hot(query);
            commit("GET_PODPORTLIST", res);
            return res.data.result;
        },
        async getDrayagePort({ commit }, data) {
            const res = await getDrayagePortApi(data);
            console.log(res, 'getDrayagePort')
            let _data = res.data.records || [];
            commit("GET_PODDRAYPORTLIST", _data);
            return _data;
        },
        async getDrayageCity({ commit }, query) {
            const res = await getDrayageCityApi(query);
            commit("GET_PODDRAYCITYLIST", res.data || []);
            return res.data;
        }
    }
}

export default freight