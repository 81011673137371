import axios from "@/utils/http"
import baseApi from "./base"
import qs from 'qs';
import fileDownload from "js-file-download"

export function billList(data) {
    return axios.post(`${baseApi.visibility}/Bill/list`, qs.stringify(data))
}

export function billDetail(data) {
    return axios.post(`${baseApi.visibility}/Bill/detail`, qs.stringify(data))
}

export function billPDF(data) {
    return axios.post(`${baseApi.visibility}/Bill/Bill_Pdf`, qs.stringify(data))
}

export function billReceiptSave(data) {
    return axios.post(`${baseApi.visibility}/Bill/receiptSave`, qs.stringify(data))
}

export function billReceiptList(data) {
    return axios.post(`${baseApi.visibility}/Bill/receiptList`, qs.stringify(data))
}

export function billExcel(data) {
    let time = '';
    if (data.etd !== undefined && data.etd.length > 0) {
        time = data.etd[0] + '~' + data.etd[1];
    } else {
        time = data.execlTime;
    }
    return axios.get(`${baseApi.visibility}/Bill/Bill_execl`, {
        params: data,
        responseType: 'blob',
    }).then(res => {
        fileDownload(res, 'Bill(' + time + ').xlsx');
    })
}
