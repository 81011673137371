import {
    billList,
    billDetail
} from "@/api/billing"

import {
    GET_BILLDETAIL,
    GET_BILLLIST
} from "./mutation-types"

const billing = {
    state: {
        billList: {},
        billDetail: {}
    },

    mutations: {
        [GET_BILLLIST]: (state, list) => {
            state.billList = list
        },
        [GET_BILLDETAIL]: (state, info) => {
            state.billDetail = info
        }
    },

    actions: {
        getBillList({ commit }, data) {
            return billList(data).then(res => {
                const list = res;
                commit("GET_BILLLIST", list.data);
            });
        },
        getBillDetail({ commit }, data) {
            return billDetail(data).then(res => {
                const info = res;
                commit("GET_BILLDETAIL", info);
            });
        }
    }
}

export default billing