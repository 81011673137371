import 'expose-loader?jQuery!jquery' // eslint-disable-line
import 'expose-loader?$!jquery' // eslint-disable-line
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';

import VueClipboard from 'vue-clipboard2';
import VueTouch from 'vue-touch';
import Trend from 'vuetrend';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default global options } */ )

import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/display.css';
import "element-ui/lib/theme-chalk/index.css"
import "@/assets/css/element-extends.scss" // element-ui override
import locale from "element-ui/lib/locale/lang/en" // lang i18n
import "@/assets/css/commons.scss";
import "@/assets/iconfont/iconfont.css"
import "@/permission" // permission control

// 图片懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

import Breadcrumb from '@/components/breadcrumb/breadcrumb.js' // 大部分页面的面包屑组件
import store from './store';
import router from './Routes';
import App from './App';
//import './registerServiceWorker'
Vue.use(BootstrapVue);
Vue.use(VueClipboard);
Vue.use(VueTouch);
Vue.use(Trend);
Vue.use(Breadcrumb);
Vue.use(ElementUI, { locale })

Vue.config.productionTip = false;
Vue.prototype.$page_sizes = [10, 20, 50, 100, 200]
Vue.prototype.$equipment = function() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
    }
    /* eslint-disable no-new */
new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App),
    data() {
        return {
            vHeight: 280,
        }
    },

    mounted() {},
    created() {
        this.$store.dispatch("setOffsetHeight").then(() => {
            this.vHeight = this.$store.state.commons.offsetHeight;
        })
    },
    methods: {

    }
});