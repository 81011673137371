export const GET_PORTLISTFROMGSETTING = "GET_PORTLISTFROMGSETTING";
export const GET_PORTLISTFROMFREIGHT = "GET_PORTLISTFROMFREIGHT";
export const SET_OFFSETHEIGHT = "SET_OFFSETHEIGHT";
export const GET_SEARCHLIST = "GET_SEARCHLIST";
export const GET_CHATLIST = "GET_CHATLIST";
export const GET_CHATDETAIL = "GET_CHATDETAIL";
export const POST_REPLY = "POST_REPLY";
export const VISIT_PAGE = "VISIT_PAGE";
export const LOGO_UPUATE = "LOGO_UPUATE";
export const LOGO_LIST = "LOGO_LIST";
export const DEL_INFO = "DEL_INFO";
export const USERRTX = "USERRTX";
export const FILEDEL = "FILEDEL";
