<script src="main.js"></script>
<template>
  <router-view v-if="isRouterAlive" />
</template>

<script>
import $ from 'jquery'
import { getToken, removeToken } from '@/utils/auth' // 验权;
import baseApi from '@/api/base'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      _gap_time: '',
      _beforeUnload_time: '',
      time_login: 120000,
      isRouterAlive: true,
      // version:
    }
  },
  mounted() {
    this.set()
    /*this._gap_time = setInterval(() => {
      setTimeout(this.set(), 0)
    }, this.time_login)*/
    window.addEventListener('beforeunload', (e) => this.beforeunloadHandler(e))
    window.addEventListener('unload', (e) => this.unloadHandler(e))
    //window.addEventListener( 'beforeunload', e => this.set() );
  },
  destroyed() {
    window.removeEventListener('beforeunload', (e) =>
      this.beforeunloadHandler(e)
    )
    window.removeEventListener('unload', (e) => this.unloadHandler(e))
  },
  created() {
    let _t = this
    _t.$store.dispatch('getStatisticeTime', {})
    const currentPath = this.$router.history.current.path

    if (currentPath === '/app') {
      this.$router.push('/app/dashboard')
    }
    this.$store.dispatch('setOffsetHeight')
    this.$store.dispatch('getWSConnect')
    this.$store.dispatch('checkFacebookAccess')
  },

  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    set() {
      let oldversion = localStorage.getItem('sysVersion')
      let _t = this
      _t.$store.dispatch('statistice_vFn', {}).then((res) => {
        let newversion = this.$store.state.user.statisticsVersion[0] || '';
        if (oldversion != newversion) {
          localStorage.setItem('sysVersion', newversion)
          location.reload(true)
        }
      })
      console.log(oldversion, 'newversion')
    },
    beforeunloadHandler(e) {
      this._beforeUnload_time = new Date().getTime()
    },
    unloadHandler(e) {
      //this.$store.dispatch("FedLogOut");
      this._gap_time = new Date().getTime() - this._beforeUnload_time
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        console.log(this._gap_time)
        localStorage.setItem('random', Math.floor(Math.random() * 1000 + 1))
        // debugger
        return false
        /*$.ajax({
          type : "POST", //请求方式
          async: false, // fasle表示同步请求，true表示异步请求
          url : baseApi.visibility + "/Index/logout",//请求地址
          data : {request_source: 1,api_token: getToken()}, //数据，json字符串
          success : function(result) { //请求成功
            removeToken();
            console.log(result);
          },
          error : function(e){  //请求失败，包含具体的错误信息
            console.log(e.status);
            console.log(e.responseText);
          }
        });*/
      }
    },
  },
}
</script>

<style src="./styles/theme.scss" lang="scss" />
