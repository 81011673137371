import Cookies from 'js-cookie'

const apiToken = 'api_token'

export function getToken() {
    return Cookies.get(apiToken)
}

export function setToken(token) {
    return Cookies.set(apiToken, token)
}

export function removeToken() {
    return Cookies.remove(apiToken)
}
