import axios from "@/utils/http"
import qs from 'qs';
import baseApi from "./base"

export function quoteRequestsList(query) {
    return axios.get(`${baseApi.visibility}/QuoteRequests/index`, { params: query })
}

export function quoteRequestsDetail(data) {
    return axios.post(`${baseApi.visibility}/QuoteRequests/detail`, qs.stringify(data))
}

export function quoteRequestsForm(data) {
    return axios.post(`${baseApi.visibility}/QuoteRequests/sendInquiry`, qs.stringify(data))
}

export function packageType(){
    return axios.post(`${baseApi.visibility}/order/getPackageType`, {});
}

export function chargesCode(){
    return axios.get(`${baseApi.visibility}/QuoteRequests/getChargesCode`, {});
}

export function sendQuote(data){
    return axios.post(`${baseApi.visibility}/QuoteRequests/sendQuote`, qs.stringify(data));
}

export function setShipmentName(data) {
    return axios.post(`${baseApi.visibility}/QuoteRequests/setShipmentName`, qs.stringify(data));
}

export function confirmQuote(data){
    return axios.post(`${baseApi.visibility}/QuoteRequests/confirmQuote`, qs.stringify(data));
}

export function historyCharges(data){
    return axios.post(`${baseApi.visibility}/QuoteRequests/getHistoryCharges`, qs.stringify(data));
}

export function deleteQuote(data) {
    return axios.post(`${baseApi.visibility}/QuoteRequests/deleteQuote`, qs.stringify(data));
}