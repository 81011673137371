import {
    userLogin,
    userLogout,
    getInfo,
    sendEmail,
    sendTrialEmail,
    newPass,
    checkCode,
    getIdByNo,
    statistics,
    statistice_time,
    statistice_v
} from "@/api/login"

import {
    getToken,
    setToken,
    removeToken
} from "@/utils/auth"

import {
    SET_TOKEN,
    SET_NAME,
    SET_AVATAR,
    SET_ROLES,
    SET_USERINFO,
    SET_LOGININFO,
    SET_SENDEMAILRES,
    SET_NEWPASS,
    GET_CHECKCODEINFO,
    SET_MID,
    ORDER_ID,
    STATISTICS,
    STATISTICSV
} from "./mutation-types"

import userImg from '../../../assets/img/user/user.jpg'

import { Message } from 'element-ui'
const user = {
    state: {
        token: getToken(),
        name: "",
        mid: '',
        avatar: userImg,
        roles: [],
        info: {},
        logininfo: {},
        sendEmailRes: {},
        newPassRes: {},
        checkCodeInfo: {},
        order_id: '',
        statistics: {},
        statisticsVersion: '',
    },

    mutations: {
        [SET_TOKEN]: (state, token) => {
            if (!token) {
                state.info = {};
                state.avatar = "";
                state.name = "";
                state.roles = [];
            }
            state.token = token
        },
        [SET_NAME]: (state, name) => {
            state.name = name
        },
        [SET_MID]: (state, mid) => {
            state.mid = mid
        },
        [SET_AVATAR]: (state, avatar) => {
            if (avatar) {
                state.avatar = avatar
            }
        },
        [SET_ROLES]: (state, roles) => {
            state.roles = roles
        },
        [SET_USERINFO]: (state, info) => {
            state.info = info
        },
        [SET_LOGININFO]: (state, info) => {
            state.logininfo = info
        },
        [SET_SENDEMAILRES]: (state, info) => {
            state.sendEmailRes = info
        },
        [SET_NEWPASS]: (state, info) => {
            state.newPassRes = info;
        },
        [GET_CHECKCODEINFO]: (state, info) => {
            state.checkCodeInfo = info
        },
        [ORDER_ID]: (state, info) => {
            state.order_id = info
        },
        [STATISTICS]: (state, info) => {
            state.statistics = info
        },
        [STATISTICSV]: (state, info) => {
            state.statisticsVersion = info
        },
    },

    actions: {
        // 登录
        login({ commit }, data) {
            return userLogin(data).then(res => {
                const list = res;
                if (list.status == 1) {
                    if (list.data.user.vop_manager == "1" || list.data.user.vop_authority) {
                        setToken(list.data.token);
                        commit("SET_TOKEN", list.data.token);
                        commit("SET_USERINFO", list);
                    } else {
                        Message.error("You don't have permissions")
                    }
                }
                console.log(list, 'listlistlist');
                // sessionStorage.set()
                commit("SET_USERINFO", list);
                commit("SET_LOGININFO", list.data.user);
            });
        },
        sendEmailByFED({ commit }, email) {
            if (email.formType == 1) {
                return sendTrialEmail(email).then(res => {
                    const info = res;
                    commit("SET_SENDEMAILRES", info)
                })
            } else {
                return sendEmail(email).then(res => {
                    const info = res;
                    commit("SET_SENDEMAILRES", info)
                })
            }
        },
        setNewPass({ commit }, pass) {
            return newPass(pass).then(res => {
                const info = res;
                commit("SET_NEWPASS", info);
            })
        },
        // 获取用户信息
        getInfo({ commit, state }) {
            return getInfo(state.token || getToken()).then((res) => {
                const info = res;
                if (info.data.roles && info.data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
                    commit("SET_ROLES", info.data.roles)
                } else {
                    commit("SET_ROLES", ["admin"])
                }
                commit("SET_NAME", info.data.user_name)
                commit("SET_MID", info.data.mid)
                commit("SET_USERINFO", info)
                commit("SET_AVATAR", info.data.headimgurl)
            })
        },
        checkCodeInfo({ commit, state }, data) {
            return checkCode(data).then(res => {
                const info = res;
                commit("GET_CHECKCODEINFO", info);
            })
        },

        // 登出
        logOut({ commit, state }) {
            return userLogout(state.token || getToken()).then(() => {
                commit("SET_TOKEN", "")
                commit("SET_ROLES", [])
                removeToken()
            })
        },

        // 前端 登出
        FedLogOut({ commit, state }) {
            // return new Promise(resolve => {
            //     commit("SET_TOKEN", "")
            //     removeToken()
            //     resolve()
            // })

            return userLogout(state.token || getToken()).then(() => {
                commit("SET_TOKEN", "")
                commit("SET_ROLES", [])
                removeToken()
            })
        },
        getIdByNo({ commit }, data) {
            return getIdByNo(data).then(res => {
                const list = res;
                console.log(list, 'getIdByNo');
                commit("ORDER_ID", list);
            });
        },
        statisticsfn({ commit }, data) {
            return statistics(data).then(res => {
                const list = res;
                console.log(list, 'statistics');
                commit("STATISTICS", list);
            });
        },
        statistice_time({ commit }, data) {
            return statistice_time(data).then(res => {
                let token = res.data
                commit("STATISTICS", token);
            });
        },

        statistice_vFn({ commit }, data) {
            return statistice_v(data).then(res => {
                let token = res.data
                commit("STATISTICSV", token);
            });
        },

    }
}

export default user