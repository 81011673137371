import axios from "@/utils/http"
import qs from 'qs';
import baseApi from "./base"
import fileDownload from "js-file-download"

export function productsList(data) {
    return axios.post(`${baseApi.visibility}/Product/ProductList`, qs.stringify(data))
}

export function productSave(data) {
    return axios.post(`${baseApi.visibility}/Product/ProductSave`, qs.stringify(data))
}

export function productDel(data) {
    return axios.post(`${baseApi.visibility}/Product/ProductDel`, qs.stringify(data))
}

export function productExcel() {
    return axios.get(`${baseApi.visibility}/Product/ProductExcel`, {
        responseType: 'blob',
    })
    .then(res => {
        fileDownload(res, 'Products.xlsx');
    })
}