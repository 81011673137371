import router from './Routes'
import store from './store'
import { Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth' // 验权
const whiteList = ['/login', '/share', '/index', '/activation'] // 不重定向白名单
router.beforeEach((to, from, next) => {
    if (getToken() || to.name == 'PA' || to.name == 'ISF' || to.name == 'Inquire' || to.name == 'Arrive') {
        store.dispatch("getInfo");
        let curr_visit_page = { curr_visit_path: to.path, curr_visit_name: to.name };
        store.dispatch("StatisticsVisit", {...curr_visit_page });
    }
    if (to.meta.requireAuth) {
        if (getToken()) {
            if (to.path === '/login') {
                next({ path: '/' })
            } else if (to.path === '/app/help') {
                store.dispatch("getInfo").then(() => {
                    next()
                }).catch((err) => {
                    Message.error("Invalid, Please log in again");
                    console.log(err);
                    removeToken()
                    next(`/login`)
                })
            } else {
                store.dispatch("getInfo").then(() => {
                    let info = store.state.user.info.data;
                    if (to.meta.authVop) {
                        if (info.vop_manager == 1) {
                            next()
                        } else if (to.meta.roleVop && info.vop_role == 1) {
                            next()
                        } else {
                            Message.error("You don't have permissions")
                        }
                    } else {
                        let index = to.meta.authCode.toString(),
                            authReg = (val, index) => val.split(',').indexOf(index) !== -1;
                        if (info.is_main == 1) {
                            next()
                        } else if (info.vop_manager == 1 || authReg(info.vop_authority, index)) {
                            next()
                        } else {
                            Message.error("You don't have permissions")
                            removeToken()
                        }
                    }
                    /* if(to.name == 'OrderDetail'){
                         if(!from.meta.keepAlive){
                             from.meta.keepAlive = true;
                         }
                         next();
                     }else if(from.name == 'OrderDetail'){
                         next();
                     }else{
                         to.meta.keepAlive = false;
                         from.meta.keepAlive = false;
                         next();
                     }*/
                }).catch((err) => {
                    Message.error("Invalid, Please log in again");
                    console.log(err);
                    removeToken()
                    next(`/login`)
                })
            }
        } else {
            if (whiteList.indexOf(to.path) !== -1) {
                next()
            } else {
                next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
            }
        }
    } else {
        next()
    }
})
router.afterEach((to, from) => {
    if (typeof(OWATracker) !== 'undefined') {
        if (window.isOWAFirstTrack) {
            window.isOWAFirstTrack = false;
        } else {
            OWATracker.trackPageView(window.location.href);
        }
    }
})