import {
    getFbInfo,
    bindFbInfo,
    unbindFbInfo,
    checkFbAccess
} from "@/api/commons"

import {
    CAN_ACCESS_FACEBOOK,
    GET_FACEBOOKSDK_LOADED,
    GET_FACEBOOKCHATSDK_LOADED
} from "./mutation-types"

const facebook = {
    state: {
        canAccessFacebook: false,
        isFacebookSDKLoaded: false,
        isFacebookChatSDKLoaded: false
    },

    mutations: {
        [CAN_ACCESS_FACEBOOK]: (state, val) => {
            state.canAccessFacebook = val
            window.canAccessFacebook = val
        },
        [GET_FACEBOOKSDK_LOADED]: (state, val) => {
            state.isFacebookSDKLoaded = val
        },
        [GET_FACEBOOKCHATSDK_LOADED]: (state, val) => {
            state.isFacebookChatSDKLoaded = val
        }
    },

    actions: {
        checkFacebookAccess({ commit }, data) {
            window.testFacebook = this
            return checkFbAccess().then(res => {
                if(res && res.data) {
                    commit("CAN_ACCESS_FACEBOOK", true);
                    if(!data) {
                        setTimeout(()=>{
                            this.dispatch('loadFacebookSDK', data)
                        }, 1000)
                    }
                }
            })
        },
        loadFacebookSDK({ commit }, data) {
            if(typeof(FB) == 'object' && FB.ui && typeof(FB.ui) == 'function') {
                return
            }
            if(data) {
                return
            }
            if(!this.state.facebook.canAccessFacebook) {
                this.dispatch('checkFacebookAccess', true)
                return
            }
            window.fbAsyncInit = function() {
                FB.init({
                    appId            : '239917286915588',
                    autoLogAppEvents : true,
                    xfbml            : true,
                    version          : 'v3.3'
                });
                FB.AppEvents.logPageView();
                commit("GET_FACEBOOKSDK_LOADED", true);
            };
            /*
            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk-login-or-share'));
            */
            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
                commit("GET_FACEBOOKCHATSDK_LOADED", true);
            }(document, 'script', 'facebook-jssdk-customerchat'));
        }
    }
}

export default facebook