import {
    productsList,
    productSave,
    productDel
} from "@/api/products"

import {
    GET_PRODUCTSLIST,
    POST_PRODUCTS,
    DEL_PRODUCTS
} from "./mutation-types"

const products = {
    state: {
        productsList: {},
        postProductsInfo: {},
        delProductsInfo: {}
    },

    mutations: {
        [GET_PRODUCTSLIST]: (state, list) => {
            state.productsList = list;
        },
        [POST_PRODUCTS]: (state, info) => {
            state.postProductsInfo = info;
        },
        [DEL_PRODUCTS]: (state, info) => {
            state.delProductsInfo = info;
        }
    },

    actions: {
        getProductsList({ commit }, data) {
            return productsList(data).then(res => {
                const list = res.data;
                commit("GET_PRODUCTSLIST", list);
            });
        },
        postProducts({ commit }, data) {
            return productSave(data).then(res => {
                const info = res;
                commit("POST_PRODUCTS", info);
            });
        },
        delProducts({ commit }, data) {
            return productDel(data).then(res => {
                const info = res;
                commit("DEL_PRODUCTS", info);
            });
        }
    }
}

export default products