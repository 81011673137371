import axios from "@/utils/http"
import qs from 'qs';
import baseApi from "./base"

export function userLogin(params) {
    return axios.post(`${baseApi.visibility}/Index/login`, qs.stringify(params))
}

export function getInfo(token) {
    return axios.post(`${baseApi.visibility}/Index/getMemberInfo`, qs.stringify({
        api_token: token
    }))
}

export function userLogout(token) {
    return axios.post(`${baseApi.visibility}/Index/logout`, qs.stringify({
        api_token: token
    }))
}

export function sendEmail(params) {
    return axios.post(`${baseApi.visibility}/Index/getVerificationCode`, qs.stringify(params))
}

export function sendTrialEmail(params) {
    return axios.post(`${baseApi.visibility}/TrialAdd/toTrialEmail`, qs.stringify(params))
}

export function newPass(params) {
    return axios.post(`${baseApi.visibility}/Index/newPass`, qs.stringify(params))
}

export function checkCode(params) {
    return axios.post(`${baseApi.visibility}/Index/checkVerificationCode`, qs.stringify(params))
}

export function setPassword(params) {
    return axios.post(`${baseApi.visibility}/index/newAccountSetPassword`, qs.stringify(params))
}

export function getIdByNo(params) {
    return axios.post(`${baseApi.visibility}/Order/getIdByNo`, qs.stringify(params))
}

export function statistics(params) {
    return axios.post(`${baseApi.visibility}/index/StatisticsLoginOut`, qs.stringify(params))
}

export function statistice_time(params) {
    return axios.post(`${baseApi.visibility}/index/statistice_time`, qs.stringify(params))
}

export function check_token(params) {
    return axios.post(`${baseApi.visibility}/index/check_token`, qs.stringify(params))
}

export function statistice_v(params) {
    return axios.post(`${baseApi.visibility}/Statistics/statistice_time`, qs.stringify(params))
}

export function loginDemoEmail(params) {
    return axios.post(`${baseApi.visibility}/Index/loginDemoEmail`, qs.stringify(params))
}
export function loginDemoCheck(params) {
    return axios.post(`${baseApi.visibility}/Index/loginDemoCheck`, qs.stringify(params))
}

export function versionClose(data) {
    return axios.get(`${baseApi.visibility}/Index/versionClose`, { params: data })
}