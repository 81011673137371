import {
    SET_POLISTS
} from "./mutation-types"

const po2 = {
    state: {
        poReplicate: {}
    },

    mutations: {
        [SET_POLISTS]: (state, obj) => {
            state.poReplicate = obj
            sessionStorage.setItem('poSetting', JSON.stringify(obj))
        }
    },

    actions: {
        setPOLists({ commit }, arr) {
            
            let baseDetail = Object.assign([], arr),
                ctnr_arr = [],
                freightType = {
                    Ocean: 1,
                    Air: 2
                },
                shipmentType = {
                    FCL: 1,
                    LCL: 2
                },
                modelForm = {
                    shipment_name: "PO:",
                    freight_type: "",
                    shipment_type: "",
                    ctnr_num: {
                        "20GP": 0,
                        "40GP": 0,
                        "40HC": 0,
                        "45HC": 0
                    },
                    gross_weight: 0,
                    measurement: 0,
                };
            if (baseDetail.transport_type == 'Air')
                modelForm.freight_type = 2
            else if (baseDetail.transport_type == 'Ocean')
                modelForm.freight_type = 1
            else 
                modelForm.freight_type = ''
            if (baseDetail.shipment_type == 'FCL')
                modelForm.shipment_type = 1
            else if (baseDetail.shipment_type == 'LCL')
                modelForm.shipment_type = 2
            else
                modelForm.shipment_type = ''
            modelForm.gross_weight = baseDetail.gross_weight
            modelForm.measurement = baseDetail.volume
            let a = ''
            for (let i = 0; i < baseDetail.poNo.length; i++) {
                a = a + baseDetail.poNo[i] + ','
            }
            modelForm.shipment_name = 'PO:' + a
            for (let j = 0; j < baseDetail.container.length; j++) {
                for (const key in modelForm.ctnr_num) {
                    if (key == baseDetail.container[j].type) {
                        modelForm.ctnr_num[key] = modelForm.ctnr_num[key] + 1
                    }
                }
            }
            for (let num in modelForm.ctnr_num) {
                if (modelForm.ctnr_num[num]) {
                    ctnr_arr.push(modelForm.ctnr_num[num] + "X" + num);
                }
            }
            modelForm.ctnr_num = ctnr_arr.splice(",").join(",");
            // for (const key in modelForm.ctnr_num) {
                
            // }
            commit("SET_POLISTS", modelForm);
        }
    }
}

export default po2
