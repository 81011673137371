import axios from "@/utils/http"
import baseApi from "./base"
import qs from 'qs';

export function portListFromSetting(data) {
    return axios.post(`${baseApi.visibility}/Management/searchPort`, qs.stringify(data))
}

export function portListFromFreight(data) {
    return axios.post(`${baseApi.visibility}/Freight/getFlightCourse`, qs.stringify(data))
}

export function topSearch(data) {
    return axios.post(`${baseApi.visibility}/order/topSearch`, qs.stringify(data))
}

export function chatDetail(data) {
    return axios.post(`${baseApi.visibility}/Cooperation/cooperationList`, qs.stringify(data))
}

export function cooperationSave(data) {
    return axios.post(`${baseApi.visibility}/Cooperation/cooperationSave`, qs.stringify(data))
}

export function portHistory(data) {
    return axios.post(`${baseApi.visibility}/QuoteRequests/getHistoryPort`, qs.stringify(data))
}

export function setPortHistory(data) {
    return axios.post(`${baseApi.visibility}/QuoteRequests/setHistoryPort`, qs.stringify(data))
}

export function setSubscription(data) {
    return axios.post(`${baseApi.visibility}/TopicSubscription/modifyTopicSub`, qs.stringify(data))
}

export function getSubscriptionList(data) {
    return axios.post(`${baseApi.visibility}/TopicSubscription/index`, qs.stringify(data))
}

export function getFbInfo(data) {
    return axios.post(`${baseApi.visibility}/Connect/fbinfo`, qs.stringify(data))
}

export function bindFbInfo(data) {
    return axios.post(`${baseApi.visibility}/Connect/fblogin`, qs.stringify(data))
}

export function unbindFbInfo(data) {
    return axios.post(`${baseApi.visibility}/Connect/fbunbind`, qs.stringify(data))
}

export function getWechatInfo(data) {
    return axios.post(`${baseApi.visibility}/Connect/wxinfo`, qs.stringify(data))
}

export function getWechatQRAuth(data) {
    return axios.post(`${baseApi.visibility}/Connect/wxqrauth`, qs.stringify(data))
}

export function getWechatLogin(data) {
    return axios.post(`${baseApi.visibility}/Connect/wxconnect`, qs.stringify(data))
}

export function unbindWechatInfo(data) {
    return axios.post(`${baseApi.visibility}/Connect/wxunbind`, qs.stringify(data))
}


export function checkFbAccess() {
    return axios.get('https://graph.facebook.com/facebook/picture?redirect=false')
}

export function checkGoogleMapAccess() {
    return axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=googleamerica')
}

export function fileExport(data) {
    return axios.post(`${baseApi.visibility}/FileExport/export`, qs.stringify(data))
}

export function fileShareExport(data) {
    return axios.post(`${baseApi.visibility}/FileExport/sendShare`, qs.stringify(data))
}

export function logoListApi(data) {
    return axios.post(`${baseApi.visibility}/FileExport/logoList`, qs.stringify(data))
}

export function logoDelete(data) {
    return axios.post(`${baseApi.visibility}/FileExport/logoDelete`, qs.stringify(data))
}

export function uploadsDosc(data) {
    return axios.post(`${baseApi.visibility}/FileExport/uploadsDosc`, qs.stringify(data))
}

export function logoUpdate(data) {
    return axios.post(`${baseApi.visibility}/FileExport/logoUpdate`, qs.stringify(data))
}

export function apiUploads(data) {
    return axios.post(`${baseApi.visibility}/ApiBase/upload`, qs.stringify(data))
}

export function getSalesList(data) {
    return axios.post(`${baseApi.visibility}/Management/getSalesList`, qs.stringify(data))
}

export function StatisticsVisit(data) {
    return axios.post(`${baseApi.visibility}/Statistics/StatisticsVisit`, qs.stringify(data))
}

export function cooperationDel(data) {
    return axios.post(`${baseApi.visibility}/Cooperation/cooperationDel`, qs.stringify(data))
}

export function userRtxApi(data) {
    return axios.post(`${baseApi.visibility}/Index/userRtx`, qs.stringify(data))
}

export function userSaleApi(params) {
    return axios.post(`${baseApi.visibility}/Index/userSale`, qs.stringify(params))
}


export function fileDelApi(params) {
    return axios.post(`${baseApi.visibility}/Index/fileDel`, qs.stringify(params))
}

export function getDestinationUrl(params) {
    return axios.post(`${baseApi.visibility}/Index/getDestinationUrl`, qs.stringify(params))
}