import Vue from 'vue';
import Vuex from 'vuex';
import commons from "./modules/commons/commons"
import app from "./modules/app/app"
import user from "./modules/user/user"
import freight from "./modules/freight/freight"
import billing from "./modules/billing/billing"
import order from "./modules/order/order"
import quotes from "./modules/quotes/quotes"
import products from "./modules/products/products"
import dashboard from "./modules/dashboard/dashboard"
import setting from "./modules/setting/setting"
import reports from "./modules/reports/reports"
import getters from "./getters"
import layout from './modules/layout/layout';
import cooperation from './modules/cooperation/cooperation';
import facebook from './modules/facebook/facebook';
import po from './modules/po/po';
import po2 from './modules/po2/po2';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layout,
    commons,
    app,
    user,
    freight,
    billing,
    order,
    quotes,
    products,
    dashboard,
    setting,
    reports,
    cooperation,
    facebook,
    po,
    po2
  },
  getters
});
