import {
  cooperationList,
  cooperationSubjectList,
  cooperationSave,
  accountList,
  cooperationGroupList,
  cooperationReadAll
} from '@/api/cooperation'

import {
  GET_COOPERATIONRESPONSE,
  GET_SAVECOOPERATIONRESPONSE,
  GET_COOPERATIONLIST,
  GET_COOPERATIONSTARMARKLIST,
  GET_COOPERATIONLISTNEW,
  GET_COOPERATIONLISTCACHE,
  GET_COOPERATIONLISTHAVEMORE,
  GET_COOPERATIONSUBJECT,
  GET_COOPERATIONSUBJECTLIST,
  GET_ACCOUNTTLIST,
  GET_UNREADCOUNT,
  GET_COOPERATIONGROUPLIST,
  GET_COOPERATIONGROUPLISTNEW,
  SET_CURRENTCHATINGSUBJECTID,
  SET_WECHAT_QR_STATUS_MSG,
  SET_WECHAT_QR_DIALOG_SHOW,
  SET_WECHAT_INFO_SCANNED,
  SET_WECHAT_INFO_AUTHED,
  SET_IS_REGISTED,
  SET_WS_HANDLER,
  SET_WS_FALLBACK_COUNT,
  GET_WS_HANDLER,
  GET_WS_FALLBACK_COUNT,
  SET_COOPERATIONTOTAL,
  SET_WS_CLOSETIME,
  SET_WS_FALLBACK_TIME,
  SET_MSG_READ_STATUE,
  SET_MSG_STAR_MARK,
  SET_ALL_READ
} from './mutation-types'
import baseApi from '@/api/base'
import { getToken } from '@/utils/auth'

const cooperation = {
  state: {
    cooperationResponse: {},
    saveCooperationResponse: {},
    cooperationList: [],
    cooperationListNew: [],
    cooperationListRead: [],
    cooperationListReadGroup: [],
    cooperationListReadBizNo: [],
    cooperationListNewStarGroup: [],
    cooperationListReadStarGroup: [],
    cooperationListHaveMore: false,
    cooperationStarMarkList: [],
    cooperationStarMarkListNew: [],
    cooperationStarMarkListRead: [],
    cooperationSubject: [],
    cooperationSubjectList: [],
    cooperationListCache: {},
    accountList: [],
    cooperationChatingBusinessId: '',
    cooperationTotal: 0,
    unreadCount: {
      quote: 0,
      order: 0,
      bill: 0,
      user: 0,
      sys: 0,
      other: 0,
      po: 0,
      all: 0
    },
    cooperationGroupList: [],
    cooperationGroupListNew: [],
    cooperationGroupListRead: [],
    currentChatingSubjectId: null,
    wechatUserName: '',
    wechatQrStatusMsg: '',
    wechatQrDialogShow: false,
    wechatInfoScanned: null,
    wechatInfoAuthed: null,
    isRegisted: false,
    wsHandler: null,
    wsFallbackCount: 0,
    wsFallbackTime: 0,
    wsCloseTime: 0
  },
  mutations: {
    [GET_COOPERATIONRESPONSE]: (state, list) => {
      state.cooperationResponse = list
    },
    [GET_SAVECOOPERATIONRESPONSE]: (state, list) => {
      state.saveCooperationResponse = list
    },
    [GET_COOPERATIONLIST]: (state, data) => {
      if (!data.list) {
        data.list = []
      }
      let list = data.list
      var oldList = state.cooperationList
      if (!data.pre_id || data.pre_id.length == 0) {
        //
        oldList = []
      }
      var arrRead = []
      var arrNew = []
      for (var idx in list) {
        if ('status_read' in list[idx]) {
          if (list[idx].status_read == 1) {
            arrNew.push(list[idx])
          } else {
            arrRead.push(list[idx])
          }
        } else {
          arrRead.push(list[idx])
        }
        oldList.push(list[idx])
      }
      var oldReadNo = state.cooperationListReadBizNo
      var arrReadGroup = state.cooperationListReadGroup
      var arrReadStarGroup = state.cooperationListReadStarGroup
      var arrNewStarGroup = state.cooperationListNewStarGroup
      if (!oldReadNo) {
        oldReadNo = []
      }
      if (!data.pre_id || data.pre_id.length == 0) {
        //
        oldReadNo = []
        arrReadGroup = []
        arrReadStarGroup = []
        arrNewStarGroup = []
      }
      for (var idx in oldList) {
        let obj = oldList[idx]
        let key = obj.business_type + '_' + obj.business_id
        if (oldReadNo.length > 0 && oldReadNo.indexOf(key) > -1) {
          // added
        } else {
          if (obj.status_read == 1) {
            if (obj.star_mark == 1) {
              arrNewStarGroup.push(obj)
            }
          }
          if (obj.status_read == 2) {
            arrReadGroup.push(obj)
            if (obj.star_mark == 1) {
              arrReadStarGroup.push(obj)
            }
          }
          oldReadNo.push(key)
        }
      }
      arrReadStarGroup = arrReadStarGroup.sort((a, b) => {
        let o = a.star_mark > b.star_mark
        return o ? 1 : 0
      })
      arrNewStarGroup = arrNewStarGroup.sort((a, b) => {
        let o = a.star_mark > b.star_mark
        return o ? 1 : 0
      })
      state.cooperationList = oldList
      state.cooperationListNew = arrNew
      state.cooperationListRead = arrRead
      state.cooperationListReadGroup = arrReadGroup
      state.cooperationListReadBizNo = oldReadNo
      state.cooperationListReadStarGroup = arrReadStarGroup
      state.cooperationListNewStarGroup = arrNewStarGroup
    },
    [GET_COOPERATIONSTARMARKLIST]: (state, data) => {
      var arrRead = []
      var arrNew = []
      var list = []
      if (data && data.list) {
        list = data.list
        state.cooperationStarMarkList = data.list
      }
      for (var idx in list) {
        var item = list[idx]
        if('star_mark' in item && item.star_mark != 1) {
            continue
        }
        if ('status_read' in item) {
          if (item.status_read == 2) {
            arrRead.push(item)
          } else {
            arrNew.push(item)
          }
        } else {
          arrRead.push(item)
        }
      }
      state.cooperationStarMarkListNew = arrNew
      state.cooperationStarMarkListRead = arrRead
    },
    [GET_COOPERATIONLISTNEW]: (state, list) => {
      state.cooperationListNew = list
    },
    [GET_COOPERATIONLISTCACHE]: (state, obj) => {
      //state.cooperationListCache = list
      if (obj && obj.key) {
        let oldList = []
        if (state.cooperationListCache[obj.key]) {
          oldList = state.cooperationListCache[obj.key]
        }
        if (!obj.lastId || obj.oldList == '') {
          oldList = []
        }
        let ids = {}
        oldList.forEach((obj, i) => {
          ids[obj.id] = obj.id
        })
        obj.list.forEach((obj, i) => {
          if (!(obj.id in ids)) {
            oldList.push(obj)
          }
        })
        //oldList = [].concat.apply(oldList, obj.list)
        oldList = oldList.sort((x, y) => {
          let t1 = Date.parse(x.create_date)
          let t2 = Date.parse(y.create_date)
          if (t1 > t2) {
            return 1
          }
          if (t1 < t2) {
            return -1
          }
          return 0
        })
        state.cooperationListCache[obj.key] = oldList
      }
    },
    [GET_COOPERATIONLISTHAVEMORE]: (state, val) => {
      state.cooperationListHaveMore = val
    },
    [GET_COOPERATIONSUBJECTLIST]: (state, list) => {
      state.cooperationSubjectList = list
    },
    [GET_ACCOUNTTLIST]: (state, list) => {
      state.accountList = list
    },
    [GET_UNREADCOUNT]: (state, list) => {
      state.unreadCount = list
    },
    [GET_COOPERATIONSUBJECT]: (state, list) => {
      state.cooperationSubject = list
    },
    [GET_COOPERATIONGROUPLIST]: (state, list) => {
      state.cooperationGroupList = list
      if (list && typeof list == 'object') {
        var unread = {
          quote: 0,
          order: 0,
          bill: 0,
          user: 0,
          sys: 0,
          other: 0,
          po: 0
        }
        var total = 0
        var arrRead = []
        var arrNew = []
        for (var k in list) {
          var tmp = list[k]
          if (tmp.unread_count && tmp.unread_count > 0 && tmp.business_type) {
            if (tmp.business_type == 1) {
              unread.quote += tmp.unread_count
            }
            if (tmp.business_type == 2) {
              unread.order += tmp.unread_count
            }
            if (tmp.business_type == 3) {
              unread.bill += tmp.unread_count
            }
            if (tmp.business_type == 4) {
              unread.user += tmp.unread_count
            }
            if (tmp.business_type == 5) {
              unread.sys += tmp.unread_count
            }
            if (tmp.business_type == 6) {
              unread.other += tmp.unread_count
            }
            if (tmp.business_type == 7) {
              unread.po += tmp.unread_count
            }
            total += tmp.unread_count
          }
          if (list[k].unread_count > 0) {
            arrNew.push(list[k])
          } else {
            arrRead.push(list[k])
          }
        }
        state.cooperationGroupListNew = arrNew
        state.cooperationGroupListRead = arrRead
        state.unreadCount = unread
      }
    },
    [GET_COOPERATIONGROUPLISTNEW]: (state, list) => {
      state.cooperationGroupListNew = list
    },
    [SET_CURRENTCHATINGSUBJECTID]: (state, list) => {
      state.currentChatingSubjectId = list
    },
    [SET_WECHAT_QR_STATUS_MSG]: (state, val) => {
      state.wechatQrStatusMsg = val
    },
    [SET_WECHAT_QR_DIALOG_SHOW]: (state, val) => {
      state.wechatQrDialogShow = val
    },
    [SET_WECHAT_INFO_SCANNED]: (state, val) => {
      state.wechatInfoScanned = val
    },
    [SET_WECHAT_INFO_AUTHED]: (state, val) => {
      state.wechatInfoAuthed = val
      if (val) {
        if (val.user_name) {
          state.wechatUserName = val.user_name
        } else if (val.openid) {
          state.wechatUserName = val.openid
        }
      }
    },
    [SET_IS_REGISTED]: (state, val) => {
      state.isRegisted = val
    },
    [SET_WS_HANDLER]: (state, val) => {
      state.wsHandler = val
    },
    [SET_WS_FALLBACK_COUNT]: (state, val) => {
      state.wsFallbackCount = val
    },
    [SET_COOPERATIONTOTAL]: (state, val) => {
      let tmp = parseInt(val)
      if (!isNaN(tmp)) {
        state.cooperationTotal = tmp
      }
    },
    [SET_WS_CLOSETIME]: (state, val) => {
      state.wsCloseTime = val
    },
    [SET_WS_FALLBACK_TIME]: (state, val) => {
      state.wsFallbackTime = val
    },
    [SET_MSG_STAR_MARK]: (state, val) => {
      for (var idx in state.cooperationListReadGroup) {
        var obj = state.cooperationListReadGroup[idx]
        if (obj && 'star_mark' in obj) {
          if (obj.id == val.msg_id) {
            let newStarMark = obj.star_mark > 0 ? 0 : 1
            obj.star_mark = newStarMark
          }
        }
      }
      for (var idx in state.cooperationGroupListNew) {
        var obj = state.cooperationGroupListNew[idx]
        if (obj && 'star_mark' in state.cooperationGroupListNew[idx]) {
          if (obj.id == val.msg_id) {
            let newStarMark = obj.star_mark > 0 ? 0 : 1
            obj.star_mark = newStarMark
          }
        }
      }
      for (var idx in state.cooperationListNewStarGroup) {
        var obj = state.cooperationListNewStarGroup[idx]
        if (obj && 'star_mark' in obj) {
          if (obj.id == val.msg_id) {
            let newStarMark = obj.star_mark > 0 ? 0 : 1
            obj.star_mark = newStarMark
          }
        }
      }
      /*
            for(var idx in state.cooperationListReadStarGroup) {
                var obj = state.cooperationListReadStarGroup[idx]
                if(obj.id == val.msg_id) {
                  let newStarMark = (obj.star_mark > 0) ? 0 : 1
                  obj.star_mark = newStarMark
                }
            }
            */
    },
    [SET_ALL_READ]: (state, val) => {
      state.unreadCount = val
    }
  },
  actions: {
    getCooperationList({ commit }, data) {
      return cooperationList(data).then(res => {
        const unreadCount = res.data.unread_count
        const list = res.data.list
        commit('GET_UNREADCOUNT', unreadCount)
        commit('GET_COOPERATIONRESPONSE', res)
        commit('GET_COOPERATIONLIST', res.data)
        if (res.data.list && res.data.list.length > 0) {
          if (data && data.business_type && data.business_id) {
            const cacheKey = data.business_type + '_' + data.business_id
            let lastId = ''
            if (data && data.last_id) {
              lastId = data.last_id
            }
            const obj = { list: list, key: cacheKey, lastId: lastId }
            commit('GET_COOPERATIONLISTCACHE', obj)
          }
        }
      })
    },
    getCooperationSubject({ commit }, data) {
      return cooperationSubjectList(data).then(res => {
        const list = res.data.list
        commit('GET_COOPERATIONSUBJECT', list)
        if (res.data.unread_count) {
          const unreadCount = res.data.unread_count
          commit('GET_UNREADCOUNT', unreadCount)
        }
      })
    },
    getCooperationSubjectList({ commit }, data) {
      return cooperationSubjectList(data).then(res => {
        if (res) {
          const list = res.data.list
          commit('GET_COOPERATIONSUBJECTLIST', list)
          if (res.data.unread_count) {
            const unreadCount = res.data.unread_count
            commit('GET_UNREADCOUNT', unreadCount)
          }
        }
      })
    },
    getCooperationAccountList({ commit }, data) {
      return accountList(data).then(res => {
        if (res) {
          const list = res.data
          commit('GET_ACCOUNTTLIST', list)
        }
      })
    },
    sendCooperationSave({ commit }, data) {
      return cooperationSave(data).then(res => {
        //
        if (res) {
          commit('GET_SAVECOOPERATIONRESPONSE', res)
        }
      })
    },
    getCooperationGroupList({ commit }, data) {
      return cooperationGroupList(data).then(res => {
        if (res) {
          commit('GET_COOPERATIONGROUPLIST', res.data)
        }
      })
    },
    getCooperationReadAll({ commit }, data) {
      commit('GET_COOPERATIONLISTNEW', [])
      commit('GET_COOPERATIONGROUPLISTNEW', [])
      return cooperationReadAll(data).then(res => {
        if (res && res.data) {
          if (res.data.unread_count) {
            commit('GET_UNREADCOUNT', res.data.unread_count)
          }
        }
      })
    },
    getCooperationStarMark({ commit }, data) {
      return cooperationStarMark(data).then(res => {
        if (res.data) {
          //
        }
      })
    },
    getWSConnect({ commit }) {
      let _t = this
      if (_t.state.cooperation.wsCloseTime) {
        let _time = new Date().valueOf()
        let _diff = _time - _t.state.cooperation.wsCloseTime
        if (_diff < 5000) {
          return
        }
      }
      var wsHandler = this.state.cooperation.wsHandler
      if (wsHandler && wsHandler.readyState == 1) {
        if (!this.state.cooperation.isRegisted) {
          this.dispatch('wsConnect')
        }
        return wsHandler
      } else {
        var wsHandler = new WebSocket(baseApi.n2sws)
        wsHandler.onclose = () => {
          let wsCloseTime = new Date().valueOf()
          console.info('closed.')
          commit('SET_WS_CLOSETIME', wsCloseTime)
          commit('SET_WS_HANDLER', null)
        }
        wsHandler.onerror = () => {
          let wsCloseTime = new Date().valueOf()
          commit('SET_WS_CLOSETIME', wsCloseTime)
          commit('SET_WS_HANDLER', null)
        }
        wsHandler.onopen = res => {}
        wsHandler.onmessage = res => {
          this.dispatch('wsOnMessage', res.data)
        }
        setTimeout(() => {
          if (wsHandler && wsHandler.readyState == 1) {
            _t.dispatch('wsConnect')
          }
        }, 800)
        //this.state.cooperation.wsHandler = wsHandler
        commit('SET_WS_HANDLER', wsHandler)
        setTimeout(() => {
          if (wsHandler && wsHandler.readyState == 1) {
            _t.dispatch('getWSAll')
          }
        }, 500)
      }
    },
    wsConnect({ commit }) {
      this.dispatch('wsSend', { act: 'connected' })
    },
    wsSend({ commit }, data) {
      var useWS = true
      if (data && data.act && data.act != 'connected') {
        if (
          !this.state.cooperation.wsHandler ||
          this.state.cooperation.wsHandler.readyState != 1
        ) {
          this.dispatch('getWSConnect')
        }
      }
      if (this.state.cooperation.wsHandler && useWS) {
        if (!data) {
          data = {}
        }
        if (typeof data == 'string') {
          data = { act: data }
        }
        data.api_token = getToken()
        if (window.n2sShowWSParams) {
          console.info(data)
        } else {
          window.n2sShowWSParams = false
        }
        if (data.act == 'connected') {
          if (data.api_token) {
            commit('SET_IS_REGISTED', true)
          } else {
            commit('SET_IS_REGISTED', false)
          }
        }

        try {
          this.state.cooperation.wsHandler.send(JSON.stringify(data))
        } catch (e) {
          useWS = false
        }
      } else if (useWS) {
        if (
          !this.state.cooperation.wsHandler ||
          this.state.cooperation.wsHandler.readyState != 1
        ) {
          console.info('ws ready state err...')
        }
      }
      if (!useWS || !this.state.cooperation.wsHandler) {
        let ts = new Date().valueOf()
        if (ts - this.state.cooperation.wsFallbackTime < 5000) {
          return
        }
        var wsfbc = this.state.cooperation.wsFallbackCount
        if (!wsfbc || wsfbc <= 0) {
          wsfbc = 0
        }
        wsfbc++
        commit('SET_WS_FALLBACK_COUNT', wsfbc)
        if (wsfbc > 60) {
          return
        }
        commit('SET_WS_FALLBACK_TIME', ts)
        if (data && data.act) {
          if (data.act == 'cooperationList') {
            this.dispatch('getCooperationList', data)
          }
          if (data.act == 'cooperationGroupList') {
            this.dispatch('getCooperationGroupList', data)
          }
          if (data.act == 'cooperationSubjectList') {
            this.dispatch('getCooperationSubjectList', data)
          }
          if (data.act == 'cooperationAccountList') {
            this.dispatch('getCooperationAccountList', data)
          }
          if (data.act == 'cooperationSave') {
            this.dispatch('sendCooperationSave', data)
          }
          if (data.act == 'accountList') {
            this.dispatch('getCooperationAccountList')
          }
          if (data.act == 'cooperationReadAll') {
            this.dispatch('getCooperationReadAll', data)
          }
          if (data.act == 'cooperationStarMark') {
            this.dispatch('getCooperationStarMark', data)
          }
          if (data.act == 'cooperationSaveViaHTTP') {
            // notify related client to update new msg count.
          }
        }
        if (this.state.cooperation.wsFallbackCount % 10 == 7) {
          this.dispatch('getWSConnect')
        }
      } else {
        commit('SET_WS_FALLBACK_COUNT', 0)
      }
    },
    wsOnMessage({ commit }, data) {
      if (typeof data == 'string') {
        data = JSON.parse(data)
      }
      if (window.n2sShowWSMessage) {
        console.info(data)
      } else {
        window.n2sShowWSMessage = false
      }
      if (data) {
        if (data.act) {
          if (data.act == 'cooperationSubjectListResult') {
            commit('GET_COOPERATIONSUBJECTLIST', data.data.list)
            if (data.data.unread_count) {
              commit('GET_UNREADCOUNT', data.data.unread_count)
            }
          }
          if (data.act == 'cooperationGroupListResult') {
            var list = data.data
            if (data.data) {
              list = data.data
            }
            commit('GET_COOPERATIONGROUPLIST', list)
            if (data.data.unread_count) {
              commit('GET_UNREADCOUNT', data.data.unread_count)
            }
          }
          if (
            data.act == 'cooperationAccountListResult' ||
            data.act == 'accountListResult'
          ) {
            commit('GET_ACCOUNTTLIST', data.data)
          }
          if (data.act == 'cooperationListResult') {
            if (data.data.total) {
              commit('SET_COOPERATIONTOTAL', data.data.total)
            }
            let haveMore = data.data.is_full_page > 0
            commit('GET_COOPERATIONLISTHAVEMORE', haveMore)
            if (data.data.list && data.data.list.length > 0) {
              for (var k in data.data.list) {
                var tmp = data.data.list[k]
                if (tmp && 'business_id' in tmp && 'business_type' in tmp) {
                  var cacheKey = tmp.business_type + '_' + tmp.business_id
                  var obj = { list: [tmp], key: cacheKey, lastId: tmp.id }
                  commit('GET_COOPERATIONLISTCACHE', obj)
                }
              }
            }
            commit('GET_COOPERATIONLIST', data.data)
            if (data.data.unread_count) {
              commit('GET_UNREADCOUNT', data.data.unread_count)
            }
          }
          if (data.act == 'cooperationStarMarkListResult') {
            if (data.data) {
              commit('GET_COOPERATIONSTARMARKLIST', data.data)
            }
          }
          if (data.act == 'cooperationUnreadCountResult') {
            commit('GET_UNREADCOUNT', data.data)
          }
          if (data.act == 'saveCooperationResult') {
            this.state.saveCooperationResponse = data.data
          }
          if (data.act == 'cooperationStarMarkResult') {
            this.dispatch('getWSCooperationStarMarkList')
          }
          if (data.act == 'accountStatusUpdated') {
            this.dispatch('getWSCooperationAccountList')
            this.dispatch('getWSCooperationSubjectList')
          }
          if (data.act == 'cooperationReceived') {
            this.dispatch('getWSAll')
            if (data.business_type && data.business_id) {
              var params = {
                //business_id: data.business_id,
                //business_type: data.business_type,
                scroll_type: 'up'
              }
              if (data.id) {
                params.last_id = data.id
              }
              if (data.last_id) {
                params.last_id = data.last_id
              }
              this.dispatch('getWSCooperationList', params)
            }
          }
          if (data.act == 'wechatQrStatusMsg') {
            if (data.data.msg) {
              commit('SET_WECHAT_QR_STATUS_MSG', data.data.msg)
            }
            if (data.data.auth_status == 0) {
              if (data.data.info) {
                commit('SET_WECHAT_INFO_SCANNED', data.data.info)
              }
            }
            if (data.data.auth_status == 1) {
              if (data.data.info) {
                commit('SET_WECHAT_INFO_AUTHED', data.data.info)
              }
              setTimeout(() => {
                commit('SET_WECHAT_QR_DIALOG_SHOW', false)
              }, 3000)
            }
          }
        }
      }
    },
    getWSAll({ commit }, data) {
      let token = getToken()
      if (token && token.length > 0) {
        this.dispatch('getWSCooperationList')
        this.dispatch('getWSCooperationGroupList')
        this.dispatch('getWSCooperationAccountList')
        this.dispatch('getWSCooperationSubject')
        this.dispatch('getWSCooperationSubjectList')
        this.dispatch('getWSCooperationUnreadCount')
      }
    },
    getWSSimple({ commit }, data) {
      let token = getToken()
      if (token && token.length > 0) {
        this.dispatch('getWSCooperationAccountList')
        this.dispatch('getWSCooperationUnreadCount')
        this.dispatch('getWSCooperationGroupList')
      }
    },
    getWSCooperationGroupList({ commit }, data) {
      if (!data) {
        data = {}
      }
      data.act = 'cooperationGroupList'
      this.dispatch('wsSend', data)
    },
    getWSCooperationAccountList({ commit }, data) {
      if (!data) {
        data = {}
      }
      data.act = 'accountList'
      this.dispatch('wsSend', data)
    },
    getWSCooperationSubject({ commit }, data) {
      if (!data) {
        data = {}
      }
      data.act = 'cooperationSubject'
      this.dispatch('wsSend', data)
    },
    getWSCooperationSubjectList({ commit }, data) {
      if (!data) {
        data = {}
      }
      data.act = 'cooperationSubjectList'
      this.dispatch('wsSend', data)
    },
    getWSCooperationList({ commit }, data) {
      if (!data) {
        data = {}
      }
      data.act = 'cooperationList'
      this.dispatch('wsSend', data)
    },
    getWSCooperationUnreadCount({ commit }, data) {
      if (!data) {
        data = {}
      }
      data.act = 'cooperationUnreadCount'
      this.dispatch('wsSend', data)
    },
    getWSCooperationSave({ commit }, data) {
      if (!data) {
        data = {}
      }
      data.act = 'cooperationSave'
      this.dispatch('wsSend', data)
    },
    getWSCooperationReadAll({ commit }, data) {
      commit('GET_COOPERATIONLISTNEW', [])
      commit('GET_COOPERATIONGROUPLISTNEW', [])
      if (!data) {
        data = {}
      }
      data.act = 'cooperationReadAll'
      this.dispatch('wsSend', data)
      var unread = {
        quote: 0,
        order: 0,
        bill: 0,
        user: 0,
        sys: 0,
        other: 0,
        po: 0
      }
      commit('GET_UNREADCOUNT', unread)
      let _t = this
      setTimeout(() => {
        _t.dispatch('getWSAll')
      }, 500)
    },
    getWSCooperationStarMark({ commit }, data) {
      if (!data) {
        data = {}
      }
      let _t = this
      commit('SET_MSG_STAR_MARK', data)
      data.act = 'cooperationStarMark'
      this.dispatch('wsSend', data)
    },
    getWSCooperationStarMarkList({ commit }, data) {
      if (!data) {
        data = { star_mark_only: 1 }
      }
      data.act = 'cooperationStarMarkList'
      this.dispatch('wsSend', data)
    },
    getWSBizSaveViaHTTP({ commit }, data) {
      if (!data) {
        data = {}
      }
      data.act = 'bizSaveViaHTTP'
      this.dispatch('wsSend', data)
    },
    getWSCooperationSaveViaHTTP({ commit }, data) {
      if (!data) {
        data = {}
      }
      data.act = 'cooperationSaveViaHTTP'
      this.dispatch('wsSend', data)
    },
    setCooperationReadStatus({ commit }, data) {
      commit('SET_MSG_READ_STATUE', data)
    },
    getWSWechatTicket({ commit }, data) {
      if (!data) {
        data = {}
      }
      data.act = 'wechatQrTicket'
      commit('SET_WECHAT_INFO_AUTHED', null)
      commit('SET_WECHAT_INFO_SCANNED', null)
      commit('SET_WECHAT_QR_STATUS_MSG', '')
      this.dispatch('wsSend', data)
    },
    getStatisticeTime({ commit }, data) {
      if (!data) {
        data = {}
      }
      data.act = 'statistice_time'

      this.dispatch('wsSend', data)
    }
  }
}

export default cooperation
