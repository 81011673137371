import {
    SET_POLIST
} from "./mutation-types"

const po = {
    state: {
        poReplicate: {}
    },

    mutations: {
        [SET_POLIST]: (state, obj) => {
            state.poReplicate = obj
            sessionStorage.setItem('poSetting', JSON.stringify(obj))
        }
    },

    actions: {
        setPOList({ commit }, arr) {
            let baseDetail = Object.assign({}, arr[0]),
                ctnr_arr = [],
                id_arr = [],
                freightType = {
                    Ocean: 1,
                    Air: 2
                },
                shipmentType = {
                    FCL: 1,
                    LCL: 2
                },
                modelForm = {
                    shipment_name: "PO:",
                    freight_type: "",
                    shipment_type: "",
                    ctnr_num: {
                        "20GP": null,
                        "40GP": null,
                        "40HC": null,
                        "45HC": null
                    },
                    gross_weight: 0,
                    measurement: 0,
                };
            //modelForm.gross_weight = parseFloat(baseDetail.container.packages.gross_weight);
            //modelForm.measurement = parseFloat(baseDetail.container.packages.volume);
            modelForm.freight_type = baseDetail.shipment.transport_type == "Other" ? "" : freightType[baseDetail.shipment.transport_type];
            modelForm.shipment_type = shipmentType[baseDetail.shipment.shipment_type]
            for (let index in arr) {
                // 拼接多个po号给shipment_name
                if(arr[index].po_detail.po_num) {
                    id_arr.push(arr[index].po_detail.po_num)
                }
                // 累加总重量
                modelForm.gross_weight = modelForm.gross_weight + parseFloat(arr[index].container.packages.gross_weight);
                // 累加总体积
                modelForm.measurement = modelForm.measurement + parseFloat(arr[index].container.packages.volume);
                // 累加箱型
                for(let ctnr in arr[index].container.container) {
                    if(arr[index].container.container[ctnr].type){
                        let item = modelForm.ctnr_num[arr[index].container.container[ctnr].type];
                        if(item) {
                            modelForm.ctnr_num[arr[index].container.container[ctnr].type] = item + 1
                        }else {
                            modelForm.ctnr_num[arr[index].container.container[ctnr].type] = 1
                        }
                    }
                }
            }
            //1X20GP,4X40GP,2X40HC
            for(let num in modelForm.ctnr_num) {
                if(modelForm.ctnr_num[num]) {
                    ctnr_arr.push(modelForm.ctnr_num[num] + "X" + num);
                }
            }
            modelForm.shipment_name = "PO：" + id_arr.splice(",").join(",");
            modelForm.ctnr_num = ctnr_arr.splice(",").join(",");
            commit("SET_POLIST", modelForm);
        }
    }
}

export default po
