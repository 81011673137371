import {
    trialList,
    trialSave,
    dredgeHandle,
    noticesList,
    saveNotices,
    sendInviteEmail
} from "@/api/setting"

import {
    GET_TRIALLIST,
    GET_DREDGEHANDLE,
    GET_NOTICESLIST,
    POST_NOTICES,
    POST_TRIALSAVE
} from "./mutation-types"

const trial = {
    state: {
        trialList: {},
        trialInfo: {},
        handleInfo: {},
        noticesList: {},
        postNotices: {}
    },

    mutations: {
        [GET_TRIALLIST]: (state, list) => {
            state.trialList = list
        },
        [POST_TRIALSAVE]: (state, info) => {
            state.trialInfo = info
        },
        [GET_DREDGEHANDLE]: (state, info) => {
            state.handleInfo = info;
        },
        [GET_NOTICESLIST]: (state, list) => {
            state.noticesList = list;
        },
        [POST_NOTICES]: (state, info) => {
            state.postNotices = info;
        },
    },

    actions: {
        getTrialList({
            commit
        }, data) {
            return trialList(data).then(res => {
                const list = res;
                commit("GET_TRIALLIST", list);
            });
        },
        postTrialSave({
            commit
        }, data) {
            return trialSave(data).then(res => {
                const info = res;
                commit("POST_TRIALSAVE", info);
            });
        },
        getDredgeHandle({
            commit
        }, data) {
            return dredgeHandle(data).then(res => {
                const info = res;
                commit("GET_DREDGEHANDLE", info)
            })
        },
        getNoticesList({
            commit
        }, data) {
            return noticesList(data).then(res => {
                const list = res;
                commit("GET_NOTICESLIST", list)
            })
        },
        postNotices({
            commit
        }, data) {
            return saveNotices(data).then(res => {
                const info = res;
                commit("POST_NOTICES", info)
            })
        },
        postSendInviteEmail({
            commit
        }, data) {
            return sendInviteEmail(data).then(res => {
                const info = res;
                commit("GET_DREDGEHANDLE", info)
            })
        },
    }
}

export default trial