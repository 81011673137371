import axios from "@/utils/http"
import qs from 'qs';
import baseApi from "./base"

export function cooperationList(data) {
    return axios.post(`${baseApi.visibility}/Cooperation/cooperationList`, qs.stringify(data))
}

export function cooperationSubjectList(data) {
    return axios.post(`${baseApi.visibility}/Cooperation/cooperationSubjectList`, qs.stringify(data))
}

export function cooperationSave(data) {
    return axios.post(`${baseApi.visibility}/Cooperation/cooperationSave`, qs.stringify(data))
}

export function accountList(data) {
    return axios.post(`${baseApi.visibility}/Cooperation/accountList`, qs.stringify(data))
}

export function cooperationReadAll(data) {
    return axios.post(`${baseApi.visibility}/Cooperation/readAll`, qs.stringify(data))
}

export function cooperationGroupList(data) {
    return axios.post(`${baseApi.visibility}/Cooperation/cooperationGroupList`, qs.stringify(data))
}

export function cooperationStarMark(data) {
    return axios.post(`${baseApi.visibility}/Cooperation/cooperationStarMark`, qs.stringify(data))
}
