import {
    orderList,
    orderDetail,
    orderDocList,
    orderSaveDocs,
    orderSaveStatus,
    orderAdd,
    orderSave,
    searchCustomer,
    getQuoteByMid,
    orderPaDocList
} from "@/api/order"

import {
    GET_ORDERLIST,
    GET_ORDERDETAIL,
    GET_ORDERDOCS,
    SAVE_ORDERDOC,
    SAVE_ORDERSTATUS,
    POST_ORDER,
    GET_ORDERCUSTOMER,
    GET_QUOTEBYMID
} from "./mutation-types"

const order = {
    state: {
        orderList: [],
        orderDocs: [],
        orderDetail: {},
        orderDocSave: {},
        orderStatusSave: {},
        orderPostInfo: {},
        orderShareInfo: {},
        orderCustomer: {},
        customerQuoteList: {}
    },

    mutations: {
        [GET_ORDERLIST]: (state, list) => {
            state.orderList = list
        },
        [GET_ORDERDETAIL]: (state, info) => {
            state.orderDetail = info
        },
        [GET_ORDERDOCS]: (state, list) =>  {
            state.orderDocs = list
        },
        [SAVE_ORDERDOC]: (state, res) => {
            state.orderDocSave = res;
        },
        [SAVE_ORDERSTATUS]: (state, res) => {
            state.orderStatusSave = res;
        },
        [POST_ORDER]: (state, info) => {
            state.orderPostInfo = info;
        },
        [GET_ORDERCUSTOMER]: (state, info) => {
            state.orderCustomer = info;
        },
        [GET_QUOTEBYMID]: (state, info) => {
            state.customerQuoteList = info;
        }
    },

    actions: {
        getOrderList({ commit }, data) {
            return orderList(data).then(res => {
                const list = res;
                commit("GET_ORDERLIST", list.data);
            });
        },
        getOrderDetail({ commit }, data) {
            return orderDetail(data).then(res => {
                const info = res;
                commit("GET_ORDERDETAIL", info.data);
            });
        },
        getOrderDocs({ commit }, data) {
            return orderDocList(data).then(res => {
                const list = res;
                commit("GET_ORDERDOCS", list.data);
            })
        },
        getPaOrderDocs({ commit }, data) {
            return orderPaDocList(data).then(res => {
                const list = res;
                commit("GET_ORDERDOCS", list.data);
            })
        },
        saveOrderDoc({ commit }, data) {
            return orderSaveDocs(data).then(res => {
                const info = res;
                commit("SAVE_ORDERDOC", info);
            });
        },
        saveOrderStatus({ commit }, data) {
            return orderSaveStatus(data).then(res => {
                const info = res;
                commit("SAVE_ORDERSTATUS", info);
            });
        },
        postOrder({ commit }, data) {
            return orderAdd(data).then(res => {
                const info = res;
                commit("POST_ORDER", info);
            })
        },
        postSaveOrder({ commit }, data) {
            return orderSave(data).then(res => {
                const info = res;
                commit("POST_ORDER", info);
            })
        },
        getOrderCustomer({ commit }, data) {
            return searchCustomer(data).then(res => {
                const info = res;
                commit("GET_ORDERCUSTOMER", info);
            })
        },
        getQuoteList({ commit }, data) {
            return getQuoteByMid(data).then(res => {
                const info = res;
                commit("GET_QUOTEBYMID", info);
            })
        }
    }
}

export default order
